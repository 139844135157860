import http from '../../http-common'

class EmployeesService {
  getAll () {
    return http.get('Employees')
  }

  getAllPagnation (params) {
    return http.get('Employees', { params })
  }

  getEmployeeBalance (params) {
    return http.get('Employees/GetEmployeeBalance', { params })
  }

  get (id) {
    return http.get(`Employees/getById?Id=${id}`)
  }

  restPassword (id) {
    return http.put(`Employees/ResetPassword?Employee_id=${id}`)
  }

  changePassword (params) {
    return http.put(`Employees/ChangePassword?Employee_id=${params.Employee_id}&oldPassword=${params.oldPassword}&newPassword=${params.newPassword}`)
  }

  search (params) {
    return http.get('Employees/Search', { params })
  }

  create (data) {
    return http.post('Employees', data)
  }

  update (id, data) {
    return http.put(`Employees?Id=${id}`, data)
  }

  delete (id) {
    return http.delete(`Employees?Id=${id}`)
  }
}

export default new EmployeesService()

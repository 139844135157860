<template>
  <form @submit.prevent="onsubmit" class="login">
    <div class="d-flex justify--center mt-3" v-if="overallError !== ''">
      <p class="overAllEerror"> {{overallError}} </p>
    </div>
    <va-input
      v-model="oldPassword"
      type="password"
      :label="$t('auth.oldPassword')"
      :error="!!oldPasswordErrors.length"
      :error-messages="oldPasswordErrors"
      :disabled="stopBTn"
      passwordView
      removable
    />
    <va-input
      v-model="newPassword"
      type="password"
      :label="$t('auth.newPassword')"
      :error="!!newPasswordErrors.length"
      :error-messages="newPasswordErrors"
      :disabled="stopBTn"
      passwordView
      removable

    />
    <va-input
      v-model="confirmPassword"
      type="password"
      :label="$t('auth.confirmPassword')"
      :error="!!confirmPasswordErrors.length"
      :error-messages="confirmPasswordErrors"
      :disabled="stopBTn"
      passwordView
      removable
    />
    <div class="row justify--center">
      <va-button type="submit" :disabled="stopBTn" class="my-0">{{ $t('auth.reset_password') }}</va-button>
    </div>
  </form>
</template>

<script>
import employeesService from '../../../services/BasicData/employeesService'
export default {
  name: 'recover-password',
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordErrors: [],
      newPasswordErrors: [],
      stopBTn: false,
      confirmPasswordErrors: [],
      overallError: '',

    }
  },
  created () {
    document.title = this.$t('generic.Leap') + '-' + this.$t('auth.recover_password')

    if (!this.$store.state.status.loggedIn) {
      this.$router.push({ name: 'login' })
    }
  },
  computed: {
    formReady () {
      return !this.oldPasswordErrors.length && !this.newPasswordErrors.length && !this.confirmPasswordErrors.length
    },
  },
  watch: {
    confirmPassword: function (val) {
      this.stopBTn = false
    },
    newPassword: function (val) {
      this.stopBTn = false
    },
    oldPassword: function (val) {
      this.stopBTn = false
    },
  },
  methods: {
    onsubmit () {
      this.stopBTn = true
      this.overallError = ''
      this.oldPasswordErrors = this.oldPassword ? [] : [this.$t('generic.errRequired')]
      if (!this.isAllowed(this.newPassword)) {
        this.newPasswordErrors = [this.$t('generic.errpasswordvalue')]
      } else {
        this.newPasswordErrors = []
      }
      if (!this.isAllowed(this.confirmPassword) || (this.confirmPassword !== this.newPassword)) {
        this.isAllowed(this.confirmPassword)
          ? this.confirmPasswordErrors = [this.$t('generic.errpasswordvalue')]
          : this.confirmPasswordErrors = [this.$t('generic.errpasswordDidnotMatch')]
      } else {
        this.confirmPasswordErrors = []
      }
      if (!this.formReady) {
        this.stopBTn = false

        return
      }
      const prams = {
        Employee_id: this.$store.state.vars.fK_Employees,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }
      employeesService.changePassword(prams).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.status == 200) {
          this.$store.dispatch('chngePass')
          localStorage.removeItem('mustlogin')
          this.$router.push({ name: 'google-maps' })
          this.stopBTn = false
          // eslint-disable-next-line eqeqeq
        } else if (response.status == 210) {
          this.overallError = response.data.message
          this.stopBTn = false
        }
      },
      ).catch(err => {
        if (err.response.status === 401) {
          this.$store.dispatch('logout')
          this.$router.push({ name: 'login' })
        }
        this.overallError = err.data.message
        this.stopBTn = false
      })
    },
    isAllowed (str) {
      var pettern = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')
      return pettern.test(str)
    },
  },
}
</script>

<style lang="scss">

.recoverLink {
  color: #03032e;
  padding: 5px;
  border: 1px solid;
  border-radius: 4px;
}

.overAllEerror {
  color: red;
}
</style>
